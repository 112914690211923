import React, { useEffect } from "react";
import { useAppDispatch, useAppSelector } from "../../app/store";
import { myApplications } from "../../redux/candidate/candidateActions";
import { GridActionsCellItem, GridColDef } from "@mui/x-data-grid";
import {
  Box,
  Step,
  StepLabel,
  Stepper,
  Tooltip,
  Typography,
  useTheme,
} from "@mui/material";
import TableContainer from "../../components/TableContainer";
import { candidateSelector } from "../../redux/candidate/candidateSlice";
import Avatar from "../../components/Avatar";
import { CandidateMyApplicationsAttributes } from "../../redux/candidate/candidateApi";
import Chip from "../../components/Chip";
import useJobStatus from "../../hooks/useJobStatus";
import Table from "../../components/Table";
import { formatDate } from "../../utils/format";
import { Link } from "react-router-dom";
import urls from "../../utils/urls";
import { Mail } from "@mui/icons-material";

const MyApplications = () => {
  const dispatch = useAppDispatch();
  const theme = useTheme();

  const candidateState = useAppSelector(candidateSelector);

  useEffect(() => {
    dispatch(myApplications());
  }, []);

  const columns: GridColDef[] = [
    {
      field: "company",
      headerName: "Empresa",
      minWidth: 250,
      valueGetter: (params) => params.row.company,
      renderCell: (params) => {
        const value =
          params.value as CandidateMyApplicationsAttributes["company"];

        return (
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              gap: 1,
            }}
          >
            <Avatar src={value.logo} name={value.name} />
            <Box>{value.name}</Box>
          </Box>
        );
      },
    },
    {
      field: "job",
      headerName: "Vaga",
      flex: 1,
      minWidth: 300,
      valueGetter: (params) => params.row.job.name,
      renderCell: (params) => {
        return params.row.job.status === "published" ? (
          <Link
            to={urls.companyJobPage
              .replace(":slug", params.row.company.slug)
              .replace(":job_id", params.row.job.id.toString())}
            target="_blank"
            style={{ textDecoration: "underline" }}
          >
            {params.value}
          </Link>
        ) : (
          params.value
        );
      },
    },
    {
      field: "process",
      headerName: "Etapa do Processo",
      width: 300,
      valueGetter: (params) => params.row.process,
      renderCell: (params) => {
        const value =
          params.value as CandidateMyApplicationsAttributes["process"];

        return (
          <Box sx={{ width: "100%" }}>
            <Stepper activeStep={value.is_excluded ? 0 : value.current_step}>
              {Array.from({ length: value.total }).map((_, index) => (
                <Step
                  key={index}
                  sx={{
                    ".Mui-active": {
                      color: value.is_excluded
                        ? "rgba(0, 0, 0, 0.38)"
                        : theme.palette.primary.main,
                    },
                  }}
                >
                  <StepLabel></StepLabel>
                </Step>
              ))}
            </Stepper>
          </Box>
        );
      },
    },
    {
      field: "status",
      headerName: "Status",
      width: 170,
      valueGetter: (params) => params.row.job.status,
      renderCell: (params) => {
        const jobStatus = useJobStatus(params.value, true);

        return (
          <Chip
            label={
              params.row.process.is_excluded ? "Finalizado" : jobStatus.name
            }
            color={
              params.row.process.is_excluded
                ? theme.palette.grey[500]
                : jobStatus.color
            }
          />
        );
      },
    },
    {
      field: "moved_at",
      headerName: "Última modificação",
      width: 170,
      valueGetter: (params) =>
        params.row.moved_at ? formatDate(params.row.moved_at, true) : null,
    },
    {
      field: "actions",
      type: "actions",
      headerName: "Ações",
      width: 100,
      cellClassName: "actions",
      getActions: ({ id, row }) => {
        return [
          <Link
            key={`send-mail-${id}`}
            to={`mailto:${row.job.owner_email}?cc=${row.job.others_email.join(
              ","
            )}`}
            target="_blank"
          >
            <Tooltip title="Entrar em contato">
              <GridActionsCellItem
                icon={<Mail />}
                label="Show"
                className="textPrimary"
                // color="primary"
              />
            </Tooltip>
          </Link>,
        ];
      },
    },
  ];

  return (
    <Box>
      <Typography variant="h5" sx={{ mb: 2 }}>
        Minhas Vagas
      </Typography>

      <TableContainer>
        <Table
          rows={candidateState.myApplications}
          columns={columns}
          loading={candidateState.isFetching}
        />
      </TableContainer>
    </Box>
  );
};

export default MyApplications;
