import React from "react";
import { DataGrid, DataGridProps } from "@mui/x-data-grid";

interface ITable extends DataGridProps {}

const Table: React.FC<ITable> = (props) => {
  return (
    <DataGrid
      initialState={{
        pagination: {
          paginationModel: {
            pageSize: 10,
          },
        },
      }}
      pageSizeOptions={[10, 25, 50, 100]}
      disableRowSelectionOnClick
      autoHeight={false}
      sx={{
        "&.MuiDataGrid-root": {
          maxHeight: "100%",
        },
        "&.MuiDataGrid-root .MuiDataGrid-cell:focus-within, &.MuiDataGrid-root .MuiDataGrid-columnHeader:focus-within":
          {
            outline: "none !important",
          },
      }}
      {...props}
    />
  );
};

export default Table;
