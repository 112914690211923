import { useAppSelector } from "../app/store";
import { JobParams } from "../redux/job/jobApi";
import { jobSelector } from "../redux/job/jobSlice";
import { userSelector } from "../redux/user/userSlice";
import { FormQuestion } from "../utils/types";

interface Options {
  job_questions?: FormQuestion[];
  job_pipelines?: JobParams["job_pipelines_attributes"];
}

export default function usePlan(key: string, options?: Options) {
  const { currentUser, users } = useAppSelector(userSelector);
  const { jobs } = useAppSelector(jobSelector);

  const getCurrentCountForFeature = (key: string): number => {
    switch (key) {
      case "users":
        return users.length;
      case "jobs":
        return jobs.length;
      case "job_questions":
        return options?.job_questions?.length || 0;
      case "job_pipelines":
        return (
          options?.job_pipelines?.filter(
            (pipeline) => !pipeline.static && !pipeline._destroy
          ).length || 0
        );
      default:
        return 0;
    }
  };

  return (
    currentUser.company &&
    currentUser.company?.plan.features[key] > getCurrentCountForFeature(key)
  );
}
