import React, { useEffect, useMemo, useState } from "react";
import { Clear, ExpandMore, Publish } from "@mui/icons-material";
import {
  Accordion,
  AccordionSummary,
  Typography,
  AccordionDetails,
  Grid,
  FormGroup,
  FormControl,
  TextField,
  SelectChangeEvent,
  MenuItem,
  Autocomplete,
  Box,
  InputAdornment,
  FormLabel,
  IconButton,
  FormControlLabel,
  Checkbox,
  useTheme,
  useMediaQuery,
  createFilterOptions,
  Tooltip,
  Chip,
} from "@mui/material";
import {
  workingTypeOptions,
  stateOptions,
  workingModelOptions,
} from "../../utils/constants";
import Editor from "../../components/Editor";
import Select from "../../components/Select";
import { FormikProps } from "formik";
import { JobParams } from "../../redux/job/jobApi";
import { useAppDispatch, useAppSelector } from "../../app/store";
import { jobSelector } from "../../redux/job/jobSlice";
import { maskCurrency } from "../../utils/masks";
import {
  createDepartment,
  getDepartments,
} from "../../redux/department/departmentActions";
import {
  DepartmentAutocomplete,
  DepartmentParams,
} from "../../redux/department/departmentApi";
import {
  clearShowState as clearDepartmentShowState,
  clearState as clearDepartmentState,
  departmentSelector,
} from "../../redux/department/departmentSlice";
import { toast } from "react-toastify";
import {
  clearShowState as clearBenefitShowState,
  clearState as clearBenefitState,
  benefitSelector,
} from "../../redux/benefit/benefitSlice";
import { createBenefit, getBenefits } from "../../redux/benefit/benefitActions";
import {
  BenefitAutocomplete,
  BenefitParams,
} from "../../redux/benefit/benefitApi";
import usePermission from "../../hooks/usePermission";
import {
  clearShowState as clearUserShowState,
  clearState as clearUserState,
  userSelector,
} from "../../redux/user/userSlice";
import { getUsers } from "../../redux/user/userActions";
import useAddress from "../../hooks/useAddress";

interface IJobDetails {
  formik: FormikProps<JobParams>;
}

const benefitFilter = createFilterOptions<BenefitAutocomplete>();
const departmentFilter = createFilterOptions<DepartmentAutocomplete>();

const JobDetails: React.FC<IJobDetails> = ({ formik }) => {
  const dispatch = useAppDispatch();
  const theme = useTheme();
  const isMediumScreen = useMediaQuery(theme.breakpoints.down("lg"));

  const hasPermissionToDepartmentsCreate = usePermission("departments.create");
  const hasPermissionToBenefitsCreate = usePermission("benefits.create");

  const [jobDetailsExpanded, setJobDetailsExpanded] = useState<boolean>(true);

  const jobState = useAppSelector(jobSelector);
  const departmentState = useAppSelector(departmentSelector);
  const benefitState = useAppSelector(benefitSelector);
  const userState = useAppSelector(userSelector);

  const {
    cities,
    loadingCities,
    stateValue,
    cityIsDisabled,
    onChangeState,
    setCitiesField,
  } = useAddress(formik, jobState.job?.state);

  useEffect(() => {
    return () => {
      dispatch(clearDepartmentState());
      dispatch(clearDepartmentShowState());
      dispatch(clearBenefitState());
      dispatch(clearBenefitShowState());
      dispatch(clearUserState());
      dispatch(clearUserShowState());
    };
  }, []);

  useEffect(() => {
    if (!formik.values.id || jobState.isFindingSuccess) {
      setCitiesField();
      dispatch(getDepartments());
      dispatch(getBenefits());
      dispatch(getUsers());
    }
  }, [formik.values.id, jobState.isFindingSuccess]);

  useEffect(() => {
    if (departmentState.isSaveSuccess) {
      dispatch(getDepartments());
      if (departmentState.department) {
        formik.setFieldValue("department", {
          id: departmentState.department.id,
          name: departmentState.department.name,
        });
      }
      toast.success("Departamento salvo com sucesso");
    }
  }, [departmentState.isSaveSuccess]);

  useEffect(() => {
    if (benefitState.isSaveSuccess) {
      dispatch(getBenefits());
      if (benefitState.benefit) {
        formik.setFieldValue(
          "benefits",
          formik.values.benefits.map((benefit) => {
            if (benefit.name === benefitState.benefit?.name) {
              return {
                ...benefit,
                id: benefitState.benefit?.id,
              };
            }
            return benefit;
          })
        );
      }
      toast.success("Benefício salvo com sucesso");
    }
  }, [benefitState.isSaveSuccess]);

  const importAllBenefits = () => {
    formik.setFieldValue("benefits", benefitState.benefits);
  };

  const jobDetailsHasError = useMemo(() => {
    return [
      "name",
      "working_type",
      "working_model",
      "state",
      "city",
      "description",
    ].some(
      (item) => formik?.errors && Object.keys(formik.errors).includes(item)
    );
  }, [formik.errors]);

  const jobOptionalDataHasError = useMemo(() => {
    return [
      "requirements",
      "benefits",
      "department",
      "minimum_salary_range",
      "maximum_salary_range",
      "internal_id",
    ].some(
      (item) => formik?.errors && Object.keys(formik.errors).includes(item)
    );
  }, [formik.errors]);

  const jobSettingsHasError = useMemo(() => {
    return ["users"].some(
      (item) => formik?.errors && Object.keys(formik.errors).includes(item)
    );
  }, [formik.errors]);

  return (
    <>
      <Accordion
        expanded={jobDetailsExpanded}
        onChange={() => setJobDetailsExpanded(!jobDetailsExpanded)}
        sx={{
          border: jobDetailsHasError
            ? `1px solid ${theme.palette.error.main}`
            : null,
        }}
      >
        <AccordionSummary expandIcon={<ExpandMore />}>
          <Typography fontWeight={"bold"}>Detalhes da vaga</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Grid container spacing={2} sx={{ mb: 2 }}>
            <Grid item xs={12} md={6}>
              <FormGroup>
                <FormControl fullWidth>
                  <TextField
                    type="text"
                    label="Título da vaga"
                    size="small"
                    required
                    onChange={formik.handleChange("name")}
                    onBlur={formik.handleBlur("name")}
                    value={formik.values.name}
                    error={!!(formik.touched.name && formik.errors.name)}
                    helperText={
                      formik.touched.name && formik.errors.name
                        ? formik.errors.name
                        : null
                    }
                  />
                </FormControl>
              </FormGroup>
            </Grid>
            <Grid item xs={12} md={6}>
              <FormGroup>
                <FormControl
                  fullWidth
                  error={
                    !!(
                      formik.touched.working_model &&
                      formik.errors.working_model
                    )
                  }
                  required
                >
                  <Select
                    labelId="working-model-label"
                    id="working-model"
                    label="Modelo de trabalho"
                    size="small"
                    onChange={(e: SelectChangeEvent<unknown>) => {
                      formik.setFieldValue("working_model", e.target.value);
                    }}
                    onBlur={formik.handleBlur("working_model")}
                    value={formik.values.working_model || ""}
                    helperText={
                      formik.touched.working_model &&
                      formik.errors.working_model
                        ? formik.errors.working_model
                        : null
                    }
                  >
                    {workingModelOptions.map((workingModelOption) => (
                      <MenuItem
                        key={workingModelOption.id}
                        value={workingModelOption.id}
                      >
                        {workingModelOption.label}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </FormGroup>
            </Grid>
            <Grid item xs={12} md={6}>
              <FormGroup>
                <FormControl fullWidth>
                  <Autocomplete
                    disablePortal
                    id="state"
                    options={stateOptions}
                    onChange={(_, option) => onChangeState(option)}
                    value={stateValue || null}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        type="text"
                        label="Estado"
                        size="small"
                        onBlur={formik.handleBlur("state")}
                        error={!!(formik.touched.state && formik.errors.state)}
                        helperText={
                          formik.touched.state && formik.errors.state
                            ? formik.errors.state
                            : null
                        }
                      />
                    )}
                  />
                </FormControl>
              </FormGroup>
            </Grid>
            <Grid item xs={12} md={6}>
              <FormGroup>
                <FormControl fullWidth>
                  <Autocomplete
                    disablePortal
                    id="city"
                    options={cities}
                    onChange={(_, option) => {
                      formik.setFieldValue("city", option || "");
                    }}
                    value={formik.values.city || null}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        type="text"
                        label="Cidade"
                        size="small"
                        onBlur={formik.handleBlur("city")}
                        error={!!(formik.touched.city && formik.errors.city)}
                        helperText={
                          formik.touched.city && formik.errors.city
                            ? formik.errors.city
                            : null
                        }
                      />
                    )}
                    loading={loadingCities}
                    disabled={cityIsDisabled}
                  />
                </FormControl>
              </FormGroup>
            </Grid>
          </Grid>
          <Grid container spacing={{ xs: 0, lg: 2 }} sx={{ mb: 2 }}>
            <Grid item xs={12} md={6}>
              <FormGroup>
                <FormControl
                  fullWidth
                  error={
                    !!(
                      formik.touched.working_type && formik.errors.working_type
                    )
                  }
                  required
                >
                  <Select
                    labelId="hiring-regime-label"
                    id="hiring-regime"
                    label="Regime de contratação"
                    size="small"
                    onChange={(e: SelectChangeEvent<unknown>) => {
                      formik.setFieldValue("working_type", e.target.value);
                    }}
                    onBlur={formik.handleBlur("working_type")}
                    value={formik.values.working_type || ""}
                    helperText={
                      formik.touched.working_type && formik.errors.working_type
                        ? formik.errors.working_type
                        : null
                    }
                  >
                    {workingTypeOptions.map((workingTypeOption) => (
                      <MenuItem
                        key={workingTypeOption.id}
                        value={workingTypeOption.id}
                      >
                        {workingTypeOption.label}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </FormGroup>
            </Grid>
            <Grid item xs={12} lg={6}>
              <FormGroup
                sx={{
                  height: "100%",
                  display: "flex",
                  justifyContent: "flex-end",
                }}
              >
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={formik.values.working_type_visible}
                      onChange={(e, checked) => {
                        formik.setFieldValue("working_type_visible", checked);
                        formik.setFieldTouched("working_type_visible", true);
                      }}
                      inputProps={{ "aria-label": "controlled" }}
                    />
                  }
                  label={
                    isMediumScreen
                      ? "Regime de contratação visível ao candidato"
                      : "Visível ao candidato"
                  }
                />
              </FormGroup>
            </Grid>
          </Grid>
          <Grid container spacing={2}>
            <Grid item xs={12} md={12}>
              <FormGroup>
                <FormControl fullWidth>
                  <Editor
                    id="description"
                    label="Descrição *"
                    value={formik.values.description || ""}
                    onEditorChange={(newValue, editor) => {
                      formik.setFieldValue("description", newValue);
                    }}
                    error={
                      !!(
                        formik.touched.description && formik.errors.description
                      )
                    }
                    helperText={
                      formik.touched.description && formik.errors.description
                        ? formik.errors.description
                        : null
                    }
                  />
                </FormControl>
              </FormGroup>
            </Grid>
          </Grid>
        </AccordionDetails>
      </Accordion>
      <Accordion
        sx={{
          border: jobOptionalDataHasError
            ? `1px solid ${theme.palette.error.main}`
            : null,
        }}
      >
        <AccordionSummary expandIcon={<ExpandMore />}>
          <Typography fontWeight={"bold"}>Dados opcionais da vaga</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Grid container spacing={2} sx={{ mb: 3 }}>
            <Grid item xs={12}>
              <FormGroup>
                <FormControl fullWidth>
                  <Autocomplete
                    disablePortal
                    multiple
                    disableClearable
                    id="requirements"
                    options={[]}
                    freeSolo
                    value={formik.values.requirements}
                    onChange={(_, value) => {
                      formik.setFieldValue("requirements", value);
                    }}
                    renderInput={(params) => {
                      params.InputProps.endAdornment = (
                        <Box sx={{ width: "100%" }}>
                          {params.InputProps.startAdornment}
                        </Box>
                      );
                      params.InputProps.startAdornment = null;

                      return (
                        <TextField
                          {...params}
                          type="text"
                          label="Requisitos"
                          size="small"
                          placeholder="Digite e Enter para cadastrar"
                          onBlur={formik.handleBlur("requirements")}
                          error={
                            !!(
                              formik.touched.requirements &&
                              formik.errors.requirements
                            )
                          }
                          helperText={
                            formik.touched.requirements &&
                            formik.errors.requirements
                              ? formik.errors.requirements
                              : null
                          }
                        />
                      );
                    }}
                    sx={{
                      "& .MuiAutocomplete-inputRoot": {
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "flex-start",
                        "& .MuiInputBase-input": {
                          width: "fill-available",
                          "@supports (-webkit-appearance:none)": {
                            width: "-webkit-fill-available",
                          },
                          "@supports (-moz-appearance:none)": {
                            width: "-moz-available",
                          },
                        },
                      },
                    }}
                  />
                </FormControl>
              </FormGroup>
            </Grid>
          </Grid>
          <Grid container spacing={2} sx={{ mb: 4 }}>
            <Grid
              item
              xs={12}
              sx={{ display: "flex", alignItems: "flex-start", gap: 1 }}
            >
              <FormGroup sx={{ flexGrow: 1 }}>
                <FormControl fullWidth>
                  <Autocomplete<BenefitAutocomplete, true, true, true>
                    id="benefits"
                    multiple
                    disablePortal
                    filterSelectedOptions
                    selectOnFocus
                    clearOnBlur
                    handleHomeEndKeys
                    autoHighlight
                    freeSolo
                    disableClearable
                    loading={benefitState.isFetching || benefitState.isSaving}
                    options={benefitState.benefits}
                    value={formik.values.benefits}
                    onChange={(_, values) => {
                      const newValues = values.map((value) => {
                        if (typeof value === "string" || value?.id === null) {
                          const newValue = {
                            id: null,
                            name:
                              typeof value === "string"
                                ? value
                                : value.inputValue,
                          } as BenefitParams;
                          dispatch(
                            createBenefit({
                              params: newValue,
                            })
                          );
                          return newValue;
                        }
                        return value;
                      });
                      formik.setFieldValue("benefits", newValues);
                    }}
                    getOptionLabel={(option) => {
                      if (typeof option === "string") {
                        return option;
                      }
                      return option.inputValue ?? option.name;
                    }}
                    isOptionEqualToValue={(option, value) => {
                      return option.id === value.id;
                    }}
                    filterOptions={(options, params) => {
                      const filtered = benefitFilter(options, params);

                      if (
                        hasPermissionToBenefitsCreate &&
                        params.inputValue !== "" &&
                        !benefitState.benefits.find(
                          (benefit) =>
                            benefit.name.toLowerCase() ===
                            params.inputValue.toLowerCase()
                        )
                      ) {
                        filtered.push({
                          id: null,
                          name: `Cadastrar "${params.inputValue}"`,
                          inputValue: params.inputValue,
                        });
                      }

                      return filtered;
                    }}
                    renderOption={(props, option) => (
                      <li {...props}>{option.name}</li>
                    )}
                    renderInput={(params) => {
                      params.InputProps.endAdornment = (
                        <Box sx={{ width: "100%" }}>
                          {params.InputProps.startAdornment}
                        </Box>
                      );
                      params.InputProps.startAdornment = null;

                      return (
                        <TextField
                          {...params}
                          type="text"
                          label="Benefícios"
                          size="small"
                          placeholder="Digite e Enter para cadastrar"
                          onBlur={formik.handleBlur("benefits")}
                        />
                      );
                    }}
                    onKeyDown={(event) => {
                      if (
                        event.key === "Enter" &&
                        !hasPermissionToBenefitsCreate
                      ) {
                        event.defaultMuiPrevented = true;
                      }
                    }}
                    sx={{
                      "& .MuiAutocomplete-inputRoot": {
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "flex-start",
                        "& .MuiInputBase-input": {
                          width: "fill-available",
                          "@supports (-webkit-appearance:none)": {
                            width: "-webkit-fill-available",
                          },
                          "@supports (-moz-appearance:none)": {
                            width: "-moz-available",
                          },
                        },
                      },
                    }}
                  />
                </FormControl>
              </FormGroup>
              <IconButton onClick={() => importAllBenefits()}>
                <Tooltip title="Importar todos benefícios">
                  <Publish />
                </Tooltip>
              </IconButton>
            </Grid>
          </Grid>
          <Grid container spacing={{ xs: 0, lg: 2 }} sx={{ mb: 2 }}>
            <Grid item xs={12} lg={8}>
              <FormGroup>
                <FormControl fullWidth>
                  <Autocomplete<DepartmentAutocomplete, false, false, true>
                    id="department"
                    disablePortal
                    selectOnFocus
                    clearOnBlur
                    handleHomeEndKeys
                    autoHighlight
                    freeSolo
                    loading={
                      departmentState.isFetching || departmentState.isSaving
                    }
                    options={departmentState.departments}
                    value={formik.values.department}
                    onChange={(_, value) => {
                      if (typeof value === "string" || value?.id === null) {
                        const newValue = {
                          id: null,
                          name:
                            typeof value === "string"
                              ? value
                              : value.inputValue,
                        } as DepartmentParams;
                        dispatch(
                          createDepartment({
                            params: newValue,
                          })
                        );
                        formik.setFieldValue("department", newValue);
                      }
                      formik.setFieldValue("department", value);
                    }}
                    getOptionLabel={(option) => {
                      if (typeof option === "string") {
                        return option;
                      }
                      return option.inputValue ?? option.name;
                    }}
                    isOptionEqualToValue={(option, value) => {
                      return option.id === value.id;
                    }}
                    filterOptions={(options, params) => {
                      const filtered = departmentFilter(options, params);

                      if (
                        hasPermissionToDepartmentsCreate &&
                        params.inputValue !== "" &&
                        !departmentState.departments.find(
                          (department) =>
                            department.name.toLowerCase() ===
                            params.inputValue.toLowerCase()
                        )
                      ) {
                        filtered.push({
                          id: null,
                          name: `Cadastrar "${params.inputValue}"`,
                          inputValue: params.inputValue,
                        });
                      }

                      return filtered;
                    }}
                    renderOption={(props, option) => (
                      <li {...props}>{option.name}</li>
                    )}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        type="text"
                        label="Departamento"
                        size="small"
                        placeholder="Digite e Enter para cadastrar"
                        onBlur={formik.handleBlur("department")}
                        error={
                          !!(
                            formik.touched.department &&
                            formik.errors.department
                          )
                        }
                        helperText={
                          formik.touched.department && formik.errors.department
                            ? formik.errors.department
                            : null
                        }
                      />
                    )}
                    onKeyDown={(event) => {
                      if (
                        event.key === "Enter" &&
                        !hasPermissionToDepartmentsCreate
                      ) {
                        event.defaultMuiPrevented = true;
                      }
                    }}
                  />
                </FormControl>
              </FormGroup>
            </Grid>
            <Grid item xs={12} lg={4}>
              <FormGroup
                sx={{
                  height: "100%",
                  display: "flex",
                  justifyContent: "flex-end",
                }}
              >
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={formik.values.department_visible}
                      onChange={(e, checked) => {
                        formik.setFieldValue("department_visible", checked);
                        formik.setFieldTouched("department_visible", true);
                      }}
                      inputProps={{ "aria-label": "controlled" }}
                    />
                  }
                  label={
                    isMediumScreen
                      ? "Departamento visível ao candidato"
                      : "Visível ao candidato"
                  }
                />
              </FormGroup>
            </Grid>
          </Grid>
          <Grid container spacing={{ xs: 0, lg: 2 }} sx={{ mb: 4 }}>
            <Grid item md={12} lg={8}>
              <FormGroup>
                <FormLabel sx={{ mb: 1 }}>Faixa Salarial</FormLabel>
                <Box
                  sx={{
                    display: "flex",
                    gap: { xs: 1, sm: 2 },
                    alignItems: "self-start",
                  }}
                >
                  <FormControl fullWidth>
                    <TextField
                      type="text"
                      label="Mínimo"
                      size="small"
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position="start">R$</InputAdornment>
                        ),
                        endAdornment: (
                          <InputAdornment
                            position="end"
                            sx={{
                              visibility: formik.values.minimum_salary_range
                                ? "visible"
                                : "hidden",
                            }}
                          >
                            <IconButton
                              size="small"
                              onClick={() =>
                                formik.setFieldValue(
                                  "minimum_salary_range",
                                  null
                                )
                              }
                            >
                              <Clear fontSize="small" />
                            </IconButton>
                          </InputAdornment>
                        ),
                      }}
                      onChange={(e) => {
                        formik.setFieldValue(
                          "minimum_salary_range",
                          maskCurrency(e.target.value)
                        );
                        formik.setFieldTouched("minimum_salary_range", true);
                      }}
                      onBlur={formik.handleBlur("minimum_salary_range")}
                      value={formik.values.minimum_salary_range ?? ""}
                      error={
                        !!(
                          formik.touched.minimum_salary_range &&
                          formik.errors.minimum_salary_range
                        )
                      }
                      helperText={
                        formik.touched.minimum_salary_range &&
                        formik.errors.minimum_salary_range
                          ? formik.errors.minimum_salary_range
                          : null
                      }
                    />
                  </FormControl>
                  <Box sx={{ pt: 1 }}>
                    <Typography>Até</Typography>
                  </Box>
                  <FormControl fullWidth>
                    <TextField
                      type="text"
                      label="Máximo"
                      size="small"
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position="start">R$</InputAdornment>
                        ),
                        endAdornment: (
                          <InputAdornment
                            position="end"
                            sx={{
                              visibility: formik.values.maximum_salary_range
                                ? "visible"
                                : "hidden",
                            }}
                          >
                            <IconButton
                              size="small"
                              onClick={() =>
                                formik.setFieldValue(
                                  "maximum_salary_range",
                                  null
                                )
                              }
                            >
                              <Clear fontSize="small" />
                            </IconButton>
                          </InputAdornment>
                        ),
                      }}
                      onChange={(e) => {
                        formik.setFieldValue(
                          "maximum_salary_range",
                          maskCurrency(e.target.value)
                        );
                        formik.setFieldTouched("maximum_salary_range", true);
                      }}
                      onBlur={formik.handleBlur("maximum_salary_range")}
                      value={formik.values.maximum_salary_range ?? ""}
                      error={
                        !!(
                          formik.touched.maximum_salary_range &&
                          formik.errors.maximum_salary_range
                        )
                      }
                      helperText={
                        formik.touched.maximum_salary_range &&
                        formik.errors.maximum_salary_range
                          ? formik.errors.maximum_salary_range
                          : null
                      }
                    />
                  </FormControl>
                </Box>
              </FormGroup>
            </Grid>
            <Grid item md={12} lg={4}>
              <FormGroup
                sx={{
                  height: "100%",
                  display: "flex",
                  justifyContent: "flex-end",
                }}
              >
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={formik.values.salary_range_visible}
                      onChange={(e, checked) => {
                        formik.setFieldValue("salary_range_visible", checked);
                        formik.setFieldTouched("salary_range_visible", true);
                      }}
                      inputProps={{ "aria-label": "controlled" }}
                    />
                  }
                  label={
                    isMediumScreen
                      ? "Faixa salarial visível ao candidato"
                      : "Visível ao candidato"
                  }
                />
              </FormGroup>
            </Grid>
          </Grid>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={4}>
              <FormGroup>
                <FormControl fullWidth>
                  <TextField
                    type="text"
                    label="ID interno"
                    size="small"
                    onChange={formik.handleChange("internal_id")}
                    onBlur={formik.handleBlur("internal_id")}
                    value={formik.values.internal_id}
                    error={
                      !!(
                        formik.touched.internal_id && formik.errors.internal_id
                      )
                    }
                    helperText={
                      formik.touched.internal_id && formik.errors.internal_id
                        ? formik.errors.internal_id
                        : null
                    }
                  />
                </FormControl>
              </FormGroup>
            </Grid>
            <Grid item xs={12} sm={8}>
              <FormGroup>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={formik.values.is_pwd}
                      onChange={(e, checked) => {
                        formik.setFieldValue("is_pwd", checked);
                        formik.setFieldTouched("is_pwd", true);
                      }}
                      inputProps={{ "aria-label": "controlled" }}
                    />
                  }
                  label={"Vaga exclusiva para PCD"}
                />
              </FormGroup>
            </Grid>
          </Grid>
        </AccordionDetails>
      </Accordion>
      <Accordion
        sx={{
          border: jobSettingsHasError
            ? `1px solid ${theme.palette.error.main}`
            : null,
        }}
      >
        <AccordionSummary expandIcon={<ExpandMore />}>
          <Typography fontWeight={"bold"}>Configurações da vaga</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <FormGroup>
                <FormControl fullWidth>
                  <Autocomplete
                    id="users"
                    multiple
                    disablePortal
                    filterSelectedOptions
                    handleHomeEndKeys
                    autoHighlight
                    disableCloseOnSelect
                    loading={userState.isFetching || userState.isSaving}
                    options={userState.users as JobParams["users"]}
                    value={formik.values.users}
                    onChange={(_, values) => {
                      formik.setFieldValue("users", values);
                    }}
                    getOptionLabel={(option) => {
                      return option.name;
                    }}
                    isOptionEqualToValue={(option, value) => {
                      return option.id === value.id;
                    }}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        type="text"
                        label="Usuários"
                        size="small"
                        onBlur={formik.handleBlur("users")}
                      />
                    )}
                    renderTags={(tagValue, getTagProps) =>
                      tagValue.map((option, index) => (
                        <Chip
                          label={option.name}
                          {...getTagProps({ index })}
                          disabled={
                            option.disabled ||
                            option.id === jobState.job?.owner.id
                          }
                        />
                      ))
                    }
                  />
                </FormControl>
              </FormGroup>
            </Grid>
          </Grid>
        </AccordionDetails>
      </Accordion>
    </>
  );
};

export default JobDetails;
