import React from "react";
import { Alert, Box, Button } from "@mui/material";
import { Link } from "react-router-dom";
import urls from "../utils/urls";
import usePermission from "../hooks/usePermission";
import { userSelector } from "../redux/user/userSlice";
import { useAppSelector } from "../app/store";

function Dashboard() {
  const { currentUser } = useAppSelector(userSelector);

  const hasPermissionToBillingsIndex = usePermission("billings.index");

  const subscriptionIsActive = ["active", "trailing"].includes(
    currentUser.company?.stripe_subscription_status || ""
  );

  return (
    <Box>
      {currentUser.id && !subscriptionIsActive ? (
        <Alert
          severity="error"
          sx={{ display: "flex", alignItems: "center", mb: 2 }}
        >
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              gap: hasPermissionToBillingsIndex ? 2 : 0,
            }}
          >
            <span>Seu plano não está ativo ou há um pagamento pendente</span>
            {hasPermissionToBillingsIndex ? (
              <Link to={urls.choosePlan}>
                <Button size="small" variant="contained">
                  Verificar
                </Button>
              </Link>
            ) : (
              <span>. Peça ao administrador da conta para verificar</span>
            )}
          </Box>
        </Alert>
      ) : null}
    </Box>
  );
}

export default Dashboard;
