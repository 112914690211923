import { AxiosResponse } from "axios";
import { ResponseWithErrors } from "../../interfaces";
import requestHelper from "../../utils/requestHelper";
import { State } from "../../utils/types";

export interface CompanyParams {
  id: number | null;
  slug: string;
  visible: boolean;
  website: string | null;
  linkedin: string | null;
  facebook: string | null;
  instagram: string | null;
  twitter: string | null;
  glassdoor: string | null;
  primary_color: string;
  name: string | null;
  slogan: string | null;
  description_title: string | null;
  description: string | null;
  logo: File | string | null;
  banner: File | string | null;
}

export interface CompanyShowResponse {
  id: number;
  slug: string;
  visible: boolean;
  website: string | null;
  linkedin: string | null;
  facebook: string | null;
  instagram: string | null;
  twitter: string | null;
  glassdoor: string | null;
  primary_color: string;
  name: string | null;
  slogan: string | null;
  description_title: string | null;
  description: string | null;
  logo: string | null;
  banner: string | null;
  jobs: Array<{
    id: number;
    name: string;
    state: State;
    working_model: string;
  }>;
}

export interface CompanyResponse
  extends CompanyShowResponse,
    ResponseWithErrors {}

const show = async (
  id: string
): Promise<AxiosResponse<CompanyShowResponse>> => {
  return await requestHelper.get(`companies/${id}`).catch((e) => e.response);
};

const update = async (
  formData: FormData
): Promise<AxiosResponse<CompanyResponse>> => {
  return await requestHelper
    .patch(`companies/${formData.get("id")}`, formData, {
      headers: { "Content-Type": "multipart/form-data" },
    })
    .catch((e) => e.response);
};

export { show, update };
