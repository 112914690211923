import React, { useMemo } from "react";
import { AddCircle } from "@mui/icons-material";
import {
  Box,
  Button,
  FormHelperText,
  FormLabel,
  useTheme,
} from "@mui/material";
import { FormikProps, getIn } from "formik";
import Header from "./Header";

interface IMultiInput {
  formik: FormikProps<any>;
  field: string;
  label: string;
  mainField: string;
  initialData: object;
  renderItem: (index: number) => React.ReactNode;
  deleteMode: "delete" | "softDelete";
  required?: boolean;
}

const MultiInput: React.FC<IMultiInput> = ({
  formik,
  field,
  label,
  mainField,
  initialData,
  renderItem,
  deleteMode,
  required = false,
}) => {
  const theme = useTheme();

  const add = () => {
    const index = eval(`formik.values.${field}`).length;
    formik.setFieldValue(`${field}.${index}`, { ...initialData, _new: true });
  };

  const error = useMemo(() => {
    return typeof getIn(formik.errors, field) === "string"
      ? getIn(formik.errors, field)
      : null;
  }, [formik.errors, field]);

  return (
    <Box>
      <Box sx={{ display: "flex", alignItems: "center", gap: 4 }}>
        <FormLabel
          error={!!error}
          sx={{
            fontSize: 16,
            color: theme.palette.common.black,
            fontWeight: "bold",
          }}
        >
          {label} {required && "*"}
        </FormLabel>
        <Button
          variant="outlined"
          startIcon={<AddCircle />}
          size="small"
          onClick={() => add()}
        >
          Adicionar
        </Button>
      </Box>

      {!!error && (
        <FormHelperText error={!!error} sx={{ ml: 0 }}>
          {error}
        </FormHelperText>
      )}

      <Box sx={{ mt: 2, display: "flex", flexDirection: "column", gap: 2 }}>
        {eval(`formik.values.${field}`).map((item: any, index: number) => {
          if (!item?._destroy) {
            return (
              <Box
                key={index}
                sx={{
                  py: 2,
                  px: { xs: 1, sm: 2 },
                  backgroundColor: (theme) => theme.palette.grey[100],
                  borderRadius: 1,
                  border: (theme) =>
                    getIn(formik.errors, `${field}.${index}`)
                      ? `1px solid ${theme.palette.error.main}`
                      : "none",
                }}
              >
                <Header
                  initialOpen={item._new}
                  initialData={initialData}
                  formik={formik}
                  field={field}
                  index={index}
                  item={item}
                  mainField={mainField}
                  deleteMode={deleteMode}
                >
                  <Box sx={{ mt: 2 }}>{renderItem(index)}</Box>
                </Header>
              </Box>
            );
          }
        })}
      </Box>
    </Box>
  );
};

export default MultiInput;
