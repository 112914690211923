import React, { useEffect, useState } from "react";
import {
  Dialog,
  DialogContent,
  DialogTitle,
  IconButton,
  Box,
  Typography,
  Tooltip,
  Tabs,
  Tab,
  Divider,
  Snackbar,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { FormikProps } from "formik";
import { JobPipelinesAttributes } from "../../redux/job/jobApi";
import Avatar from "../Avatar";
import SocialLinks from "./SocialLinks";
import Rating from "./Rating";
import PersonalDetails from "./ModalTabs/PersonalDetails";
import Resume from "./ModalTabs/Resume";
import Notes from "./ModalTabs/Notes";
import ProfessionalDetails from "./ModalTabs/ProfessionalDetails";
import { useAppDispatch } from "../../app/store";
import { getCandidateNotes } from "../../redux/candidateNote/candidateNoteActions";
import { Mail, Share } from "@mui/icons-material";
import { Link, useParams } from "react-router-dom";
import useViewportHeight from "../../hooks/useViewportHeight";
import urls from "../../utils/urls";
import CopyToClipboard from "react-copy-to-clipboard";

interface ITabs {
  id: string;
  label: string;
}

interface IModalProps {
  candidateData: {
    candidate: JobPipelinesAttributes["candidates"][number];
    pipelineIndex: number;
    candidateIndex: number;
  } | null;
  candidateFormik: FormikProps<any>;
  jobIsCompleted: boolean;
  onClose: () => void;
}

const Modal: React.FC<IModalProps> = ({
  candidateData,
  candidateFormik,
  jobIsCompleted,
  onClose,
}) => {
  const dispatch = useAppDispatch();
  const { id } = useParams();
  const { viewportHeight } = useViewportHeight();

  const [tabs, setTabs] = useState<ITabs[]>([]);
  const [tabValue, setTabValue] = useState<number>(0);
  const [snackbarOpen, setSnackbarOpen] = useState<boolean>(false);

  const candidate = candidateData?.candidate;

  useEffect(() => {
    if (candidate) {
      dispatch(getCandidateNotes(candidate.id));
    }
  }, [candidate]);

  useEffect(() => {
    const newTabs: ITabs[] = [];
    if (candidate) {
      if (candidate.address || candidate.cover_letter || candidate.summary) {
        newTabs.push({
          id: "personal-details",
          label: "Detalhes Pessoais",
        });
      }
      if (
        candidate.educations.length > 0 ||
        candidate.work_experiences.length > 0 ||
        candidate.questions.length > 0
      ) {
        newTabs.push({
          id: "professional-details",
          label: "Detalhes Profissionais",
        });
      }
      if (candidate.resume) {
        newTabs.push({
          id: "resume",
          label: "Currículo",
        });
      }
      newTabs.push({
        id: "notes",
        label: "Notas",
      });
    }
    setTabs(newTabs);
  }, [candidate]);

  const renderTabContent = () => {
    if (!candidate) {
      return;
    }

    const tabId = tabs[tabValue]?.id || "";

    switch (tabId) {
      case "personal-details":
        return <PersonalDetails candidate={candidate} />;
      case "professional-details":
        return <ProfessionalDetails candidate={candidate} />;
      case "resume":
        return <Resume candidate={candidate} />;
      case "notes":
        return <Notes candidate={candidate} jobIsCompleted={jobIsCompleted} />;
      default:
        return null;
    }
  };

  const candidateProfileUrl = () => {
    if (!candidate || !id) {
      return "";
    }

    return `${window.location.origin}${urls.candidatesJob.replace(
      ":id",
      id.toString()
    )}?candidate_id=${candidate.id}`;
  };

  const handleCopyUrl = () => {
    setSnackbarOpen(true);
  };

  return (
    <>
      <Snackbar
        message="Link Copiado"
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "right",
        }}
        autoHideDuration={2000}
        onClose={() => setSnackbarOpen(false)}
        open={snackbarOpen}
      />
      <Dialog
        open={Boolean(candidate)}
        onClose={onClose}
        PaperProps={{
          sx: {
            position: "fixed",
            right: 0,
            width: {
              xs: "100vw",
              md: "70vw",
              lg: "50vw",
            },
            height: {
              xs: viewportHeight,
              sm: "100vh",
            },
            maxHeight: "none",
            maxWidth: "none",
            margin: 0,
            padding: 0,
            borderRadius: 0,
          },
        }}
      >
        {candidate ? (
          <>
            <DialogTitle sx={{ m: 0 }}>
              <Box sx={{ display: "flex", gap: 2, mr: 4 }}>
                {candidate.avatar ? (
                  <Avatar
                    src={candidate.avatar}
                    name={candidate.name}
                    width={80}
                    height={80}
                    fontSize={30}
                  />
                ) : null}
                <Box sx={{ overflow: "hidden", width: "100%" }}>
                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                    }}
                  >
                    <Tooltip title={candidate.name} placement="bottom">
                      <Typography
                        sx={{
                          fontSize: 20,
                          fontWeight: "bold",
                          overflow: "hidden",
                          whiteSpace: "nowrap",
                          textOverflow: "ellipsis",
                        }}
                      >
                        {candidate.name}
                      </Typography>
                    </Tooltip>
                    <Box sx={{ display: "flex", gap: 1 }}>
                      <Link to={`mailto:${candidate.email}`} target="_blank">
                        <Tooltip title="Enviar E-mail">
                          <IconButton size="small">
                            <Mail fontSize="small" />
                          </IconButton>
                        </Tooltip>
                      </Link>

                      <Link to="#">
                        <CopyToClipboard
                          text={candidateProfileUrl()}
                          onCopy={handleCopyUrl}
                        >
                          <Tooltip title="Compartilhar Perfil">
                            <IconButton size="small">
                              <Share fontSize="small" />
                            </IconButton>
                          </Tooltip>
                        </CopyToClipboard>
                      </Link>
                    </Box>
                  </Box>

                  <SocialLinks candidate={candidate} />

                  <Rating
                    formik={candidateFormik}
                    candidate={candidate}
                    pipelineIndex={candidateData.pipelineIndex}
                    candidateIndex={candidateData.candidateIndex}
                    disabled={jobIsCompleted}
                  />
                </Box>
              </Box>
              <IconButton
                aria-label="close"
                onClick={onClose}
                sx={{
                  position: "absolute",
                  right: 8,
                  top: 8,
                  color: (theme) => theme.palette.grey[500],
                }}
              >
                <CloseIcon />
              </IconButton>
            </DialogTitle>

            <Tabs
              value={tabValue}
              onChange={(_, value) => setTabValue(value)}
              variant="scrollable"
              scrollButtons="auto"
              allowScrollButtonsMobile
              sx={{ px: 3 }}
            >
              {tabs.map((tab) => (
                <Tab
                  key={tab.id}
                  label={tab.label}
                  sx={{ fontWeight: "bold", fontSize: 16 }}
                />
              ))}
            </Tabs>
            <Divider />

            <DialogContent>
              <Box>{renderTabContent()}</Box>
            </DialogContent>
          </>
        ) : null}
      </Dialog>
    </>
  );
};

export default Modal;
