import { Route, Routes as RoutesDom, useLocation } from "react-router-dom";
import PrivateRoute from "./components/PrivateRoute";
import PageNotFound from "./screens/PageNotFound";
import Login from "./screens/Login";
import Signup from "./screens/SignUp";
import Company from "./screens/CompanyPage";
import Dashboard from "./screens/Dashboard";
import Website from "./screens/Website";
import PublicOnlyRoute from "./components/PublicOnlyRoute";
import Nav from "./components/Nav";
import Jobs from "./screens/Jobs";
import ManageJobs from "./screens/ManageJobs";
import urls from "./utils/urls";
import Departments from "./screens/Departments";
import Benefits from "./screens/Benefits";
import ManageDepartments from "./screens/ManageDepartments";
import ManageBenefits from "./screens/ManageBenefits";
import CompanyJob from "./screens/CompanyPage/Job";
import CompanyApplyJob from "./screens/CompanyPage/ApplyJob";
import Profiles from "./screens/Profiles";
import ManageProfiles from "./screens/ManageProfiles";
import Users from "./screens/Users";
import ManageUsers from "./screens/ManageUsers";
import AcceptInvitation from "./screens/AcceptInvitation";
import ForgotPassword from "./screens/ForgotPassword";
import RecoverPassword from "./screens/RecoverPassword";
import CandidateLogin from "./screens/Candidate/Login";
import CandidateJobs from "./screens/CandidateJobs";
import CandidateMyApplications from "./screens/Candidate/MyApplications";
import CandidateProfile from "./screens/Candidate/Profile";
import CandidateRoute from "./components/CandidateRoute";
import ReactGA from "react-ga4";
import { useEffect } from "react";
import Billings from "./screens/Billings";
import ChoosePlan from "./screens/ChoosePlan";
import ProcessingPayment from "./screens/ProcessingPayment";

function Routes() {
  const location = useLocation();

  useEffect(() => {
    if (process.env.REACT_APP_GA_ID) {
      ReactGA.initialize(process.env.REACT_APP_GA_ID);
    }

    ReactGA.send({
      hitType: "pageview",
      page: location.pathname,
      title: document.title,
    });
  }, [location]);

  return (
    <RoutesDom>
      <Route
        path={urls.login}
        element={
          <PublicOnlyRoute>
            <Login />
          </PublicOnlyRoute>
        }
      />
      <Route
        path={urls.signup}
        element={
          <PublicOnlyRoute>
            <Signup />
          </PublicOnlyRoute>
        }
      />
      <Route path={urls.forgotPassword} element={<ForgotPassword />} />
      <Route path={urls.recoverPassword} element={<RecoverPassword />} />
      <Route path={urls.acceptInvitation} element={<AcceptInvitation />} />
      <Route path={urls.companyPage} element={<Company />} />
      <Route path={urls.companyJobPage} element={<CompanyJob />} />
      <Route
        path={urls.companyApplyJobPage}
        element={
          <CandidateRoute>
            <CompanyApplyJob />
          </CandidateRoute>
        }
      />

      <Route path={urls.candidateLogin} element={<CandidateLogin />} />

      <Route
        path={urls.choosePlan}
        element={
          <Nav>
            <PrivateRoute>
              <ChoosePlan />
            </PrivateRoute>
          </Nav>
        }
      />

      <Route
        path={urls.processingPayment}
        element={
          <PrivateRoute>
            <ProcessingPayment />
          </PrivateRoute>
        }
      />

      <Route
        path={urls.dashboard}
        element={
          <PrivateRoute>
            <Nav selected="dashboard">
              <Dashboard />
            </Nav>
          </PrivateRoute>
        }
      />
      <Route
        path={urls.jobs}
        element={
          <PrivateRoute>
            <Nav selected="jobs">
              <Jobs />
            </Nav>
          </PrivateRoute>
        }
      />
      <Route
        path={urls.newJob}
        element={
          <PrivateRoute>
            <Nav selected="jobs">
              <ManageJobs />
            </Nav>
          </PrivateRoute>
        }
      />
      <Route
        path={urls.manageJob}
        element={
          <PrivateRoute>
            <Nav selected="jobs">
              <ManageJobs />
            </Nav>
          </PrivateRoute>
        }
      />
      <Route
        path={urls.candidatesJob}
        element={
          <PrivateRoute>
            <Nav selected="jobs">
              <CandidateJobs />
            </Nav>
          </PrivateRoute>
        }
      />
      <Route
        path={urls.billings}
        element={
          <PrivateRoute>
            <Nav selected="billings">
              <Billings />
            </Nav>
          </PrivateRoute>
        }
      />
      <Route
        path={urls.users}
        element={
          <PrivateRoute>
            <Nav selected="users">
              <Users />
            </Nav>
          </PrivateRoute>
        }
      />
      <Route
        path={urls.newUser}
        element={
          <PrivateRoute>
            <Nav selected="users">
              <ManageUsers />
            </Nav>
          </PrivateRoute>
        }
      />
      <Route
        path={urls.manageUser}
        element={
          <PrivateRoute>
            <Nav selected="users">
              <ManageUsers />
            </Nav>
          </PrivateRoute>
        }
      />
      <Route
        path={urls.profiles}
        element={
          <PrivateRoute>
            <Nav selected="profiles">
              <Profiles />
            </Nav>
          </PrivateRoute>
        }
      />
      <Route
        path={urls.newProfile}
        element={
          <PrivateRoute>
            <Nav selected="profiles">
              <ManageProfiles />
            </Nav>
          </PrivateRoute>
        }
      />
      <Route
        path={urls.manageProfile}
        element={
          <PrivateRoute>
            <Nav selected="profiles">
              <ManageProfiles />
            </Nav>
          </PrivateRoute>
        }
      />
      <Route
        path={urls.website}
        element={
          <PrivateRoute>
            <Nav selected="website">
              <Website />
            </Nav>
          </PrivateRoute>
        }
      />
      <Route
        path={urls.departments}
        element={
          <PrivateRoute>
            <Nav selected="departments">
              <Departments />
            </Nav>
          </PrivateRoute>
        }
      />
      <Route
        path={urls.newDepartment}
        element={
          <PrivateRoute>
            <Nav selected="departments">
              <ManageDepartments />
            </Nav>
          </PrivateRoute>
        }
      />
      <Route
        path={urls.manageDepartment}
        element={
          <PrivateRoute>
            <Nav selected="departments">
              <ManageDepartments />
            </Nav>
          </PrivateRoute>
        }
      />
      <Route
        path={urls.benefits}
        element={
          <PrivateRoute>
            <Nav selected="benefits">
              <Benefits />
            </Nav>
          </PrivateRoute>
        }
      />
      <Route
        path={urls.newBenefit}
        element={
          <PrivateRoute>
            <Nav selected="benefits">
              <ManageBenefits />
            </Nav>
          </PrivateRoute>
        }
      />
      <Route
        path={urls.manageBenefit}
        element={
          <PrivateRoute>
            <Nav selected="benefits">
              <ManageBenefits />
            </Nav>
          </PrivateRoute>
        }
      />
      <Route
        path={urls.adminCandidateMyApplications}
        element={
          <PrivateRoute>
            <Nav selected="candidateMyApplications">
              <CandidateMyApplications />
            </Nav>
          </PrivateRoute>
        }
      />
      <Route
        path={urls.adminCandidateProfile}
        element={
          <PrivateRoute>
            <Nav selected="candidateProfile">
              <CandidateProfile />
            </Nav>
          </PrivateRoute>
        }
      />
      <Route path="*" element={<PageNotFound />} />
    </RoutesDom>
  );
}

export default Routes;
