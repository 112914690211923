import axios from "axios";
import { getToken, saveToken } from "./localStorage";
import statusCodes from "./statusCodes";
import { refreshToken } from "../redux/user/userApi";
import { toast } from "react-toastify";
import urls from "./urls";
import { texts } from "./texts";

export const baseUrl = `${process.env.REACT_APP_BACKEND_URL}/api/v1`;

const requestHelper = axios.create({
  baseURL: baseUrl,
  headers: {
    "Content-Type": "application/json",
  },
});

requestHelper.interceptors.request.use(function (config) {
  const token = getToken();
  if (token) {
    config.headers.Authorization = `Bearer ${token.access_token}`;
  }
  return config;
});

requestHelper.interceptors.response.use(
  (response) => {
    return response;
  },

  async (error) => {
    const originalConfig = error.config;
    if (error.response) {
      if (
        error.response.status === statusCodes.unauthorized &&
        !originalConfig._retry
      ) {
        originalConfig._retry = true;
        return handleRefreshToken(originalConfig);
      } else if (error.response.status === statusCodes.forbidden) {
        toast.error(texts.notAuthorized);
      } else if (error.response.status === statusCodes.payment_required) {
        toast.error(error.response.data.error);
      } else {
        return Promise.reject(error);
      }
    }
    return Promise.reject(error);
  }
);

async function handleRefreshToken(originalConfig: any) {
  const response = await refreshToken(getToken()?.refresh_token || null);

  const token = response.data;
  const { access_token, refresh_token } = token;

  if (!access_token) {
    // window.location.href = urls.login;
    return Promise.reject();
  }

  saveToken({
    access_token,
    refresh_token,
  });

  originalConfig.headers.Authorization = `Bearer ${access_token}`;
  return requestHelper(originalConfig);
}

export default requestHelper;
