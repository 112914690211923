import React, { useEffect, useState } from "react";
import {
  GridActionsCellItem,
  GridColDef,
  GridRowId,
} from "@mui/x-data-grid";
import { useAppDispatch, useAppSelector } from "../app/store";
import { destroyProfile, getProfiles } from "../redux/profile/profileActions";
import { profileSelector } from "../redux/profile/profileSlice";
import { toast } from "react-toastify";
import { Edit, Delete } from "@mui/icons-material";
import { LoadingButton } from "@mui/lab";
import {
  Box,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  Button,
  Typography,
  Tooltip,
} from "@mui/material";
import { Link } from "react-router-dom";
import urls from "../utils/urls";
import usePermission from "../hooks/usePermission";
import { texts } from "../utils/texts";
import TableContainer from "../components/TableContainer";
import Table from "../components/Table";

function Profiles() {
  const dispatch = useAppDispatch();

  const hasPermissionToProfilesShow = usePermission("profiles.show");
  const hasPermissionToProfilesCreate = usePermission("profiles.create");
  const hasPermissionToProfilesUpdate = usePermission("profiles.update");
  const hasPermissionToProfilesDestroy = usePermission("profiles.destroy");

  const [deleteDialogOpen, setDeleteDialogOpen] = useState<boolean>(false);
  const [idToDelete, setIdToDelete] = useState<GridRowId | null>(null);

  const profileState = useAppSelector(profileSelector);

  useEffect(() => {
    dispatch(getProfiles());
  }, []);

  const handleOpenDeleteDialog = (id: GridRowId) => {
    setIdToDelete(id);
    setDeleteDialogOpen(true);
  };

  const handleCloseDeleteDialog = () => {
    setDeleteDialogOpen(false);
    setIdToDelete(null);
  };

  const handleDelete = async () => {
    if (idToDelete) {
      await dispatch(destroyProfile(idToDelete.toString())).then((value) => {
        if (value.meta.requestStatus !== "rejected") {
          toast.success("Benefício excluído com sucesso");
          dispatch(getProfiles());
        } else {
          toast.error(profileState.errorMessage);
        }
      });
    }
    handleCloseDeleteDialog();
  };

  const columns: GridColDef[] = [
    {
      field: "name",
      headerName: "Nome",
      flex: 1,
      minWidth: 400,
    },
    {
      field: "actions",
      type: "actions",
      headerName: "Ações",
      width: 100,
      cellClassName: "actions",
      getActions: ({ id, row }) => {
        const actions = [
          <Tooltip
            key={`edit-${id}`}
            title={
              hasPermissionToProfilesShow || hasPermissionToProfilesUpdate
                ? "Atualizar"
                : texts.notAuthorized
            }
          >
            <Box>
              <GridActionsCellItem
                icon={
                  <Link to={urls.manageProfile.replace(":id", id.toString())}>
                    <Edit />
                  </Link>
                }
                label="Edit"
                className="textPrimary"
                disabled={
                  !hasPermissionToProfilesShow && !hasPermissionToProfilesUpdate
                }
                // color="primary"
              />
            </Box>
          </Tooltip>,
        ];

        if (row.editable) {
          actions.push(
            <Box key={`delete-${id}`}>
              <Tooltip
                title={
                  hasPermissionToProfilesDestroy
                    ? "Excluir"
                    : texts.notAuthorized
                }
              >
                <Box>
                  <GridActionsCellItem
                    icon={<Delete />}
                    label="Delete"
                    onClick={() => handleOpenDeleteDialog(id)}
                    disabled={!hasPermissionToProfilesDestroy}
                    // color="error"
                  />
                </Box>
              </Tooltip>
              <Dialog open={deleteDialogOpen} onClose={handleCloseDeleteDialog}>
                <DialogTitle>Confirmar exclusão</DialogTitle>
                <DialogContent>
                  <DialogContentText>
                    Tem certeza de que deseja excluir este perfil?
                  </DialogContentText>
                </DialogContent>
                <DialogActions>
                  <Button variant="outlined" onClick={handleCloseDeleteDialog}>
                    Cancelar
                  </Button>
                  <LoadingButton
                    variant="contained"
                    onClick={handleDelete}
                    color="error"
                    loading={profileState.isDestroying}
                  >
                    Excluir
                  </LoadingButton>
                </DialogActions>
              </Dialog>
            </Box>
          );
        }

        return actions;
      },
    },
  ];

  return (
    <Box>
      <Typography variant="h5">Perfis</Typography>
      <Box sx={{ mt: 2, mb: 2, display: "flex", justifyContent: "flex-end" }}>
        <Tooltip
          title={!hasPermissionToProfilesCreate ? texts.notAuthorized : ""}
        >
          <Box>
            <LoadingButton
              variant="contained"
              type="submit"
              size="large"
              disabled={!hasPermissionToProfilesCreate}
            >
              <Link to={urls.newProfile}>Adicionar novo perfil</Link>
            </LoadingButton>
          </Box>
        </Tooltip>
      </Box>

      <TableContainer>
        <Table rows={profileState.profiles} columns={columns} loading={profileState.isFetching} />
      </TableContainer>
    </Box>
  );
}

export default Profiles;
